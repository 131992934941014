import type React from "react";
import { useQuery } from "@tanstack/react-query";
import { Card, CardHeader, CardContent, CardFooter } from "./ui/card";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";
import { parseAsInteger, useQueryState } from "nuqs";
import {
	Table,
	TableHeader,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
} from "./ui/table";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationPrevious,
	PaginationLink,
	PaginationNext,
} from "./ui/pagination";
import { InfoIcon, Gauge } from "lucide-react";
import { api } from "@/lib/api";
import {
	CheckCircledIcon,
	CrossCircledIcon,
	LightningBoltIcon,
} from "@radix-ui/react-icons";
import { Skeleton } from "./ui/skeleton";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useDialogContext } from "@/contexts/dialogContext";
import { formatDuration } from "@/utils/formatDuration";
import { fromSun } from "@/utils/convertCurrency";

const ITEMS_PER_PAGE = 9;
const MAX_VISIBLE_PAGES = 5;
export const TABLE_QUERY_KEY = "tableData";

interface Order {
	id: number;
	txId: string;
	createdAt: Date;
	updatedAt: Date;
	showAfter: Date;
	resourceType: "BANDWIDTH" | "ENERGY";
	resourceAmount: number;
	freeze: bigint;
	frozen: bigint;
	duration: number;
	price: number;
	payment: bigint;
	status: "PENDING" | "COMPLETED" | "CANCELLED" | "UNCONFIRMED" | "FAILED";
	targetAddress: string;
	originAddress: string;
}

interface Transaction {
	id: string;
	createdAt: Date;
	payoutAddress: string;
	originAddress: string;
	frozen: bigint;
	reward: number;
	paymentAt: Date | null;
	paymentTx: string | null;
	orderId: number;
}

type TabType = "orders" | "myOrders" | "myReceipts";

function isOrder(item: Order | Transaction): item is Order {
	return "resourceType" in item;
}

function isTransaction(item: Order | Transaction): item is Transaction {
	return "originAddress" in item;
}

export const OrdersTable: React.FC = () => {
	const { connected, address } = useWallet();
	const [currentPage, setCurrentPage] = useQueryState(
		"page",
		parseAsInteger.withDefault(1),
	);
	const [activeTab, setActiveTab] = useQueryState("activeTab", {
		defaultValue: "orders",
	});
	const { openDialog } = useDialogContext();

	const { data, isLoading, error } = useQuery({
		queryKey: [TABLE_QUERY_KEY, currentPage, activeTab, address],
		queryFn: async () => {
			if (activeTab === "myReceipts") {
				const res = await api.transactions.index.get({
					headers: { "x-wallet": address ?? "" },
					query: {
						page: currentPage,
						perPage: ITEMS_PER_PAGE,
					},
				});
				return res.data;
			}
			const queryParams = {
				page: currentPage,
				perPage: ITEMS_PER_PAGE,
				...(activeTab === "myOrders" && address ? { wallet: address } : {}),
			};
			const res = await api.orders.index.get({ query: queryParams });
			return res.data;
		},
		refetchInterval: 15000,
	});

	if (isLoading) return <TableSkeleton />;
	if (error) return <div>An error occurred: {(error as Error).message}</div>;

	const items = data?.data || [];
	const { totalItems, perPage } = data?.metadata || {};

	const totalPages = Math.ceil(totalItems / perPage);

	const generatePageNumbers = () => {
		if (totalPages <= MAX_VISIBLE_PAGES) {
			return Array.from({ length: totalPages }, (_, i) => i + 1);
		}

		let startPage = Math.max(currentPage - Math.floor(MAX_VISIBLE_PAGES / 2), 1);
		let endPage = startPage + MAX_VISIBLE_PAGES - 1;

		if (endPage > totalPages) {
			endPage = totalPages;
			startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 1);
		}

		return Array.from(
			{ length: endPage - startPage + 1 },
			(_, i) => startPage + i,
		);
	};

	const pageNumbers = generatePageNumbers();

	const renderTableContent = () => {
		if (activeTab === "myReceipts") {
			return (
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Date</TableHead>
							<TableHead>Order</TableHead>
							<TableHead>Tx</TableHead>
							<TableHead>Payout Address</TableHead>
							<TableHead>Delegated</TableHead>
							<TableHead>Payment</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{items.length === 0 ? (
							<TableRow>
								<TableCell colSpan={6} className="p-5 text-center font-semibold">
									No transactions yet.
								</TableCell>
							</TableRow>
						) : (
							items.map((item) => {
								if (!isTransaction(item)) return null;
								return (
									<TableRow key={item.id}>
										<TableCell>
											<div>
												<div className="font-medium">
													{new Intl.DateTimeFormat(undefined, {
														hour: "numeric",
														minute: "numeric",
														hour12: false,
													}).format(new Date(item.createdAt))}
												</div>
												<div className="text-muted-foreground text-sm">
													{new Intl.DateTimeFormat(undefined, {
														day: "numeric",
														month: "short",
													}).format(new Date(item.createdAt))}
												</div>
											</div>
										</TableCell>
										<TableCell>
											<span
												className="cursor-pointer text-primary underline"
												onClick={() => openDialog("orderDetails", { id: item.orderId })}
											>
												#{item.orderId}
											</span>
										</TableCell>
										<TableCell className="font-mono">
											<a
												className="text-muted-foreground underline"
												// new window
												target="_blank"
												rel="noreferrer"
												href={`https://tronscan.org/#/transaction/${item.id}`}
											>
												{item.id.substring(0, 10)}...
											</a>
										</TableCell>
										<TableCell className="font-mono">
											<a
												className="underline"
												target="_blank"
												rel="noreferrer"
												href={`https://tronscan.org/#/address/${item.payoutAddress}`}
											>
												{item.payoutAddress.substring(0, 12)}...
											</a>
										</TableCell>
										<TableCell>
											<div className="flex items-baseline gap-1">
												{Number(fromSun(item.frozen)).toLocaleString()}
												<span className="font-semibold text-red-400 text-xs">TRX</span>
											</div>
										</TableCell>
										<TableCell className="flex-col items-center justify-start space-y-1">
											<div className="flex items-baseline gap-1">
												{fromSun(item.reward).toLocaleString()}
												<span className="font-semibold text-red-400 text-xs">TRX</span>
											</div>
											<div className="text-muted-foreground text-xs">
												{item.paymentAt ? (
													<a
														className="text-green-400 underline"
														target="_blank"
														rel="noreferrer"
														href={`https://tronscan.org/#/transaction/${item.paymentTx}`}
													>
														Paid at{" "}
														{new Intl.DateTimeFormat(undefined, {
															day: "numeric",
															month: "short",
															hour: "numeric",
															minute: "numeric",
															hour12: false,
														}).format(new Date(item.paymentAt))}
													</a>
												) : (
													<span className="text-yellow-500">Payment pending</span>
												)}
											</div>
										</TableCell>
									</TableRow>
								);
							})
						)}
					</TableBody>
				</Table>
			);
		}
		return (
			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className="md:w-28">Date</TableHead>
						<TableHead className="text-right md:w-[8.5rem]">Resource</TableHead>
						<TableHead className="text-right md:w-32">Price</TableHead>
						<TableHead className="text-right md:w-32">Payout</TableHead>
						<TableHead>Fulfilled</TableHead>
						{connected && <TableHead>Action</TableHead>}
					</TableRow>
				</TableHeader>
				<TableBody>
					{items.map((order) => {
						if (!isOrder(order)) return null;
						return (
							<TableRow key={order.id}>
								<TableCell className="flex items-center space-x-1 ">
									<Button
										variant="ghost"
										size="icon"
										className="h-8 w-8 p-0"
										onClick={() => openDialog("orderDetails", { id: order.id })}
									>
										<InfoIcon className="h-4 w-4" />
									</Button>
									<div>
										<div className="font-medium">
											{new Intl.DateTimeFormat(undefined, {
												hour: "numeric",
												minute: "numeric",
												hour12: false,
											}).format(new Date(order.createdAt))}
										</div>
										<div className="text-muted-foreground text-xs">
											{new Intl.DateTimeFormat(undefined, {
												day: "numeric",
												month: "short",
											}).format(new Date(order.createdAt))}
										</div>
									</div>
								</TableCell>
								<TableCell>
									<div className="flex items-center justify-end space-x-1">
										<span
											className={
												order.resourceType === "ENERGY" ? "text-primary" : "text-blue-500"
											}
										>
											{order.resourceAmount.toLocaleString()}
										</span>
										{order.resourceType === "ENERGY" ? (
											<LightningBoltIcon className="h-4 w-4 text-primary" />
										) : (
											<Gauge className="h-4 w-4 text-blue-500" />
										)}
									</div>
									<div className="flex items-center justify-end text-muted-foreground text-xs ">
										/ {formatDuration(order.duration)}
									</div>
								</TableCell>
								<TableCell>
									<div className="flex justify-end gap-1">
										<span className="font-bold">{order.price}</span> sun
									</div>
									{/* APY calculation would go here if available */}
								</TableCell>
								{order.status !== "UNCONFIRMED" ? (
									<>
										<TableCell>
											<div className="flex items-baseline justify-end gap-1">
												<span className="font-bold">
													{fromSun(Number(order.payment) * 0.7)}
												</span>
												<span className="font-semibold text-red-400 text-xs">TRX</span>
											</div>
											<div className="flex justify-end text-muted-foreground text-xs">
												{fromSun(order.payment)} TRX
											</div>
										</TableCell>
										<TableCell>
											<div className="relative h-8 w-full max-w-32">
												<div className="flex items-center justify-center font-medium">
													{(() => {
														if (order.freeze.toString() === "0") return "0%";
														let percentage =
															(Number(order.frozen) / Number(order.freeze)) * 100;
														percentage = Math.min(100, percentage);
														return percentage % 1 === 0
															? `${Math.floor(percentage)}%`
															: `${percentage.toFixed(2)}%`;
													})()}
												</div>
												<Progress
													value={
														order.freeze.toString() !== "0"
															? Math.min(
																	100,
																	(Number(order.frozen) / Number(order.freeze)) * 100,
																)
															: 0
													}
												/>
											</div>
										</TableCell>
										{connected && (
											<TableCell>
												{order.targetAddress !== address && order.status === "PENDING" ? (
													<Button
														variant="outline"
														className="w-full"
														onClick={() => openDialog("fillOrder", { id: order.id })}
													>
														SELL
													</Button>
												) : order.status === "COMPLETED" ? (
													<div className="flex items-center justify-center">
														<CheckCircledIcon className="h-5 w-5" />
													</div>
												) : (
													<div className="flex items-center justify-center">
														<CrossCircledIcon className="h-5 w-5" />
													</div>
												)}
											</TableCell>
										)}
									</>
								) : (
									<TableCell colSpan={3}>
										<div className="flex items-center justify-center text-yellow-500">
											Confirming order...
										</div>
									</TableCell>
								)}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		);
	};

	return (
		<Card className="flex flex-col md:h-[880px]">
			<CardHeader>
				<Tabs
					value={activeTab}
					onValueChange={(value) => {
						setActiveTab(value as TabType);
						setCurrentPage(1);
					}}
				>
					<TabsList>
						<TabsTrigger value="orders">Orders</TabsTrigger>
						<TabsTrigger value="myOrders" disabled={!address}>
							My Orders
						</TabsTrigger>
						<TabsTrigger value="myReceipts" disabled={!address}>
							My Receipts
						</TabsTrigger>
					</TabsList>
				</Tabs>
			</CardHeader>
			<CardContent className="flex-grow overflow-auto">
				{renderTableContent()}
			</CardContent>
			<CardFooter className="flex-col gap-2">
				{/* {activeTab === "myOrders" && (
					<Card className="flex w-full items-center justify-center gap-2 p-2 text-muted-foreground">
						<ClockIcon className="h-4 w-4" />
						Orders will be open to public 10 minutes from creation.
					</Card>
				)} */}
				<div className="flex w-full flex-col items-start justify-between gap-4 sm:flex-row sm:items-center">
					<div className="text-muted-foreground text-sm">
						Showing <strong>{items.length}</strong> of <strong>{totalItems}</strong>{" "}
						{activeTab === "myReceipts" ? "transactions" : "orders"}
					</div>
					{totalPages > 1 && (
						<div className="flex w-full justify-center sm:w-auto">
							<Pagination>
								<PaginationContent>
									<PaginationItem>
										<PaginationPrevious
											onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
											// disabled={currentPage === 1}
										/>
									</PaginationItem>
									{pageNumbers.map((pageNumber) => (
										<PaginationItem key={pageNumber}>
											<PaginationLink
												onClick={() => setCurrentPage(pageNumber)}
												isActive={currentPage === pageNumber}
											>
												{pageNumber}
											</PaginationLink>
										</PaginationItem>
									))}
									<PaginationItem>
										<PaginationNext
											onClick={() =>
												setCurrentPage((prev) => Math.min(totalPages, prev + 1))
											}
											// disabled={currentPage === totalPages}
										/>
									</PaginationItem>
								</PaginationContent>
							</Pagination>
						</div>
					)}
				</div>
			</CardFooter>
		</Card>
	);
};

const TableSkeleton: React.FC = () => {
	return (
		<Card className="flex flex-col md:h-[880px]">
			<CardHeader>
				<Skeleton className="h-10 w-[300px]" />
			</CardHeader>
			<CardContent className="flex-grow overflow-auto">
				<Table>
					<TableHeader>
						<TableRow>
							{[...Array(5)].map((_, index) => (
								// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
								<TableHead key={index}>
									<Skeleton className="h-6 w-full" />
								</TableHead>
							))}
						</TableRow>
					</TableHeader>
					<TableBody>
						{[...Array(ITEMS_PER_PAGE)].map((_, index) => (
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							<TableRow key={index}>
								{[...Array(5)].map((_, cellIndex) => (
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									<TableCell key={cellIndex}>
										<Skeleton className="h-10 w-full" />
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
			<CardFooter>
				<div className="flex w-full justify-between">
					<Skeleton className="h-8 w-[100px]" />
					<Skeleton className="h-8 w-[200px]" />
				</div>
			</CardFooter>
		</Card>
	);
};
