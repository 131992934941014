import { Building2, Globe, Mail, MessageCircle, Shield } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { useDialogContext } from "@/contexts/dialogContext";

export function Footer() {
	const { openDialog } = useDialogContext();

	return (
		<footer className="border-t bg-card/80 py-6 text-card-foreground text-md shadow-sm backdrop-blur-sm">
			<div>
				{/* Logo Section */}
				<div className="flex flex-col items-center text-center">
					<img
						src="/logo-footer.webp"
						alt="Tronspace.vip Logo"
						className="mb-6 w-[300px]"
					/>

					<h2 className="mb-4 font-semibold text-xl">What is Tronspace.vip</h2>
					<p className="mb-8 max-w-2xl text-muted-foreground">
						Tronspace.vip is a complimentary energy trading platform within the TRON
						ecosystem. Our mission is to provide TRON traders with lower burning fees
						for energy consumption, enhanced discounts, and a secure and efficient
						energy self-rental service.
					</p>

					<div className="mx-auto mb-12 grid max-w-4xl grid-cols-1 gap-8 md:grid-cols-3">
						<div className="flex flex-col items-center text-center">
							<div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-br from-blue-400 to-blue-600">
								<Building2 className="h-8 w-8 text-white" />
							</div>
							<h3 className="mb-2 font-semibold text-xl">Economy</h3>
							<p className="text-muted-foreground">
								Fast TRX transfers, 88% fee reduction. Earn commissions with idle TRX.
								Streamlined, efficient, and profitable energy trading.
							</p>
						</div>

						<div className="flex flex-col items-center text-center">
							<div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-br from-orange-400 to-orange-600">
								<Globe className="h-8 w-8 text-white" />
							</div>
							<h3 className="mb-2 font-semibold text-xl">Efficient</h3>
							<p className="text-muted-foreground">
								Discover our API, manual, and batch transfers—user-friendly and swift.
								Simply provide your address and save on energy costs. Trusted by Tron
								users.
							</p>
						</div>

						<div className="flex flex-col items-center text-center">
							<div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-br from-teal-400 to-green-400">
								<Shield className="h-8 w-8 text-white" />
							</div>
							<h3 className="mb-2 font-semibold text-xl">Safety</h3>
							<p className="text-muted-foreground">
								As the global leader in TRON energy trading, we prioritize stability,
								efficiency, and innovation. 24/7 customer service and technical support
								included.
							</p>
						</div>
					</div>

					{/* Custom Divider */}
					<div className="mb-12 flex w-full justify-center">
						<div className="w-1/2 border-border border-t" />
					</div>

					{/* API Section */}
					<div className="mb-8 text-center">
						<h2 className="mb-2 font-semibold text-xl">Automate Api</h2>
						<p className="text-muted-foreground">Coming soon...</p>
					</div>
				</div>
				<div className="mx-auto w-full max-w-[1250px] border-t px-2 pt-8">
					<div className="grid grid-cols-1 gap-8 md:grid-cols-3 lg:grid-cols-4">
						<div className="space-y-3">
							<h3 className="font-semibold text-lg">About</h3>
							<ul className="space-y-2">
								<li>
									<p
										onClick={() => openDialog("faq", {})}
										className="cursor-pointer text-muted-foreground transition-colors hover:text-primary"
									>
										FAQ
									</p>
								</li>
								<li>
									<p
										className="cursor-pointer text-muted-foreground transition-colors hover:text-primary"
										onClick={() => openDialog("terms", {})}
									>
										Terms & Conditions
									</p>
								</li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="font-semibold text-lg">Support</h3>
							<ul className="space-y-2">
								<li>
									<a
										href="mailto:support@tronspace.vip"
										className="inline-flex items-center gap-2 text-muted-foreground transition-colors hover:text-primary"
									>
										<Mail className="h-4 w-4" />
										Email Support
									</a>
								</li>
								<li>
									<a
										href="https://t.me/tronspace_vip"
										target="_blank"
										rel="noopener noreferrer"
										className="inline-flex items-center gap-2 text-muted-foreground transition-colors hover:text-primary"
									>
										<MessageCircle className="h-4 w-4" />
										Telegram Support
									</a>
								</li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="font-semibold text-lg">Resources</h3>
							<ul className="space-y-2">
								{/* <li>
									<Link
										href="/documentation"
										className="text-muted-foreground transition-colors hover:text-primary"
									>
										Documentation
									</Link>
								</li> */}
								<li className="text-muted-foreground transition-colors hover:text-primary">
									{/* <Link
										href="/api"
										className="text-muted-foreground transition-colors hover:text-primary"
									> */}
									API (Coming soon)
									{/* </Link> */}
								</li>
							</ul>
						</div>
					</div>
					<div className="mt-8 text-muted-foreground text-sm">
						<p>© {new Date().getFullYear()} Tronspace VIP. All rights reserved.</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
