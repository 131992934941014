import { tronWeb } from "@/lib/tronWeb";
import { fromSun } from "@/utils/convertCurrency";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { toast } from "sonner";

export const TRON_ACCOUNT_KEY = "tronAccount";

interface PermissionKey {
	address: string;
	weight: number;
}
export interface TronAccount {
	permissions: {
		id: number;
		type: string;
		name: string;
		threshold: number;
		keys: PermissionKey[];
	}[];
	isMultiSig: boolean;
	balance: number;
	available: number;
	staked: number;
	energy_staked: number;
	energy_delegated: number;
	energy_delegated_res: number;
	energy_acquired: number;
	energy_delegatable: number;
	bandwidth_staked: number;
	bandwidth_delegated: number;
	bandwidth_delegated_res: number;
	bandwidth_acquired: number;
	bandwidth_delegatable: number;
	resource: {
		energy: {
			conversion_rate: number;
			used: number;
			total: number;
			recovery_time: number;
		};
		bandwidth: {
			conversion_rate: number;
			used: number;
			total: number;
			recovery_time: number;
		};
	};
}

export function useTronAccount(): UseQueryResult<TronAccount, Error> {
	const { connected, address } = useWallet();
	return useQuery<TronAccount, Error>({
		queryKey: [TRON_ACCOUNT_KEY, address],
		queryFn: async () => {
			if (!connected || !address) {
				throw new Error("Wallet not connected");
			}

			return await getTronAccount(address);
		},
		enabled: connected && !!address,
		refetchInterval: 20000,
	});
}

export const getTronAccount = async (
	address: string,
	permissionTarget?: string,
): Promise<TronAccount> => {
	try {
		const trxAccount = await tronWeb.trx.getAccount(address);
		const resource = await tronWeb.trx.getAccountResources(address);
		const targetHexAddress = permissionTarget
			? tronWeb.address.toHex(permissionTarget)
			: null;

		const account: TronAccount = {
			permissions: [],
			isMultiSig: false,
			balance: 0,
			available: fromSun(trxAccount.balance),
			staked: 0,
			energy_staked: 0,
			energy_delegated: 0,
			energy_delegated_res: 0,
			energy_acquired: 0,
			energy_delegatable: 0,
			bandwidth_staked: 0,
			bandwidth_delegated: 0,
			bandwidth_delegated_res: 0,
			bandwidth_acquired: 0,
			bandwidth_delegatable: 0,
			resource: {
				energy: {
					conversion_rate: 0,
					used: 0,
					total: 0,
					recovery_time: 0,
				},
				bandwidth: {
					conversion_rate: 0,
					used: 0,
					total: 0,
					recovery_time: 0,
				},
			},
		};

		const checkPermissionKeys = (keys: PermissionKey[]): boolean => {
			if (!targetHexAddress) return true;
			return keys.some(
				(key) => tronWeb.address.fromHex(key.address) === permissionTarget,
			);
		};

		if (
			trxAccount.owner_permission?.keys &&
			trxAccount.owner_permission.keys.length > 0
		) {
			if (checkPermissionKeys(trxAccount.owner_permission.keys)) {
				account.isMultiSig = trxAccount.owner_permission.keys.length > 1;
				account.permissions.push({
					id: 0,
					type: "owner",
					name: "Owner",
					threshold: trxAccount.owner_permission.threshold,
					keys: trxAccount.owner_permission.keys,
				});
			}
		}

		if (trxAccount.active_permission && trxAccount.active_permission.length > 0) {
			trxAccount.active_permission.map((permission) => {
				if (checkPermissionKeys(permission.keys)) {
					account.isMultiSig = true;
					account.permissions.push({
						id: permission.id || 2,
						type: "active",
						name: permission.permission_name,
						threshold: permission.threshold,
						keys: permission.keys,
					});
				}
			});
		}

		// Process V2 staking and delegation
		if (trxAccount.account_resource) {
			if (trxAccount.account_resource.delegated_frozenV2_balance_for_energy) {
				account.energy_delegated += fromSun(
					trxAccount.account_resource.delegated_frozenV2_balance_for_energy,
				);
			}
			if (
				trxAccount.account_resource.acquired_delegated_frozenV2_balance_for_energy
			) {
				account.energy_acquired += fromSun(
					trxAccount.account_resource.acquired_delegated_frozenV2_balance_for_energy,
				);
			}
		}

		if (trxAccount.delegated_frozenV2_balance_for_bandwidth) {
			account.bandwidth_delegated += fromSun(
				trxAccount.delegated_frozenV2_balance_for_bandwidth,
			);
		}

		if (trxAccount.acquired_delegated_frozenV2_balance_for_bandwidth) {
			account.bandwidth_acquired += fromSun(
				trxAccount.acquired_delegated_frozenV2_balance_for_bandwidth,
			);
		}

		if (trxAccount.frozenV2) {
			for (const frozen of trxAccount.frozenV2) {
				if (frozen.amount) {
					if (frozen.type) {
						account.energy_staked = fromSun(frozen.amount);
					} else {
						account.bandwidth_staked = fromSun(frozen.amount);
					}
				}
			}
		}

		// Calculate total staked and balance
		account.staked =
			account.energy_staked +
			account.energy_delegated +
			account.bandwidth_staked +
			account.bandwidth_delegated;
		account.balance = account.available + account.staked;

		// Process resource information
		if (trxAccount.account_resource?.energy_window_size) {
			account.resource.energy.recovery_time =
				3 * trxAccount.account_resource.energy_window_size;
		}

		if (trxAccount.net_window_size) {
			account.resource.bandwidth.recovery_time = 3 * trxAccount.net_window_size;
		}

		account.resource.energy.conversion_rate =
			resource.TotalEnergyWeight / resource.TotalEnergyLimit;
		account.resource.bandwidth.conversion_rate =
			resource.TotalNetWeight / resource.TotalNetLimit;

		if (resource.freeNetLimit) {
			account.resource.bandwidth.total += resource.freeNetLimit;
		}
		if (resource.NetLimit) {
			account.resource.bandwidth.total += resource.NetLimit;
		}
		if (resource.freeNetUsed) {
			account.resource.bandwidth.used += resource.freeNetUsed;
		}
		if (resource.NetUsed) {
			account.resource.bandwidth.used += resource.NetUsed;
		}
		if (resource.EnergyLimit) {
			account.resource.energy.total += resource.EnergyLimit;
		}
		if (resource.EnergyUsed) {
			account.resource.energy.used += resource.EnergyUsed;
		}

		if (account.bandwidth_delegated) {
			account.bandwidth_delegated_res = Math.floor(
				fromSun(
					trxAccount.delegated_frozenV2_balance_for_bandwidth /
						account.resource.bandwidth.conversion_rate,
				),
			);
		}
		if (account.energy_delegated) {
			account.energy_delegated_res = Math.floor(
				fromSun(
					trxAccount.account_resource.delegated_frozenV2_balance_for_energy /
						account.resource.energy.conversion_rate,
				),
			);
		}

		// Get delegatable energy
		const energy = await tronWeb.trx.getCanDelegatedMaxSize(address, "ENERGY");
		if (energy.max_size) {
			account.energy_delegatable = Math.floor(
				fromSun(energy.max_size) / account.resource.energy.conversion_rate,
			);
		}
		const bandwidth = await tronWeb.trx.getCanDelegatedMaxSize(
			address,
			"BANDWIDTH",
		);
		if (bandwidth.max_size) {
			account.bandwidth_delegatable = Math.floor(
				fromSun(bandwidth.max_size) / account.resource.bandwidth.conversion_rate,
			);
		}

		return account;
	} catch (error) {
		toast.error(`Error fetching Tron account: ${error}`);
		console.error("Error fetching Tron account:", error);
		throw error;
	}
};
